// 予約保留
$(function(){
  $(document).on('click', 'button.ordered-btn',function(){
    var order_array = $(this).data('array');
    reservedOrderButtonClicked(order_array);
  });

  $(document).on('click', 'button.reserved-btn',function(){
    var portal_flg = $(this).data('flg');
    var target = $(this).data('target');
    reserved(portal_flg, target);
  });

function reserved(portal_flg, target) {
    var post_url = $('#post_url').text();
    var update_url = '/' + post_url + '/order/reserved';
    if (portal_flg) {
      update_url = update_url + '?portal_flg=true';
    }
    $.ajax({
            type: 'POST',
            url: '/' + post_url + '/order/ajax_check_project',
            async: false,
            timeout: 10000,
            cache: false
          }).done(function(data) {
              switch (data) {
              case "OK":
                $.ajax({
                  url: update_url,
                  type: 'POST'
                }).done(function(response){
                    $(`.${target}`).html(response);
                  });
                  break;
              case "NG":
                location.href = '/' + post_url;
                break;
              }
          });
  };
});