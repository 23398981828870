// テキストボックスに入力された全角数字を半角数字に変換

window.zen2hanNum = function (obj){
  var str = obj.value;
  var hankaku = str.replace(/[０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
  obj.value = hankaku;
}

// ＋(プラス)ボタンクリック処理
// 数量に1加算
// 引数 id: 数量欄のID
//      autoload: true=商品名、金額再表示(注文番号注文画面専用)
window.incrementQuantityById = function(id,autoload) {
  var obj = $('#' + id);
if (obj != null) {
  var qty = obj.val();
  if(isNaN(qty) || qty <= 0) qty = 0;
  if(qty < 99) qty = parseInt(qty) + 1;
  obj.val(qty);
  if(autoload) productNameAutoLoad(obj, true);
}
return false;
}

$(document).on('click', '.increment_quantity_btn', function(){
  var id = $(this).data('id');
  var autoload = $(this).data('autoload');
  incrementQuantityById(id, autoload);
});

// －(マイナス)ボタンクリック処理
// 数量から1減算
// 引数 id: 数量欄のID
//      autoload: true=商品名、金額再表示(注文番号注文画面専用)
window.decrementQuantityById = function(id,autoload) {
  var obj = $('#' + id);
if (obj != null) {
  var qty = obj.val();
  if(isNaN(qty) || qty <= 0) qty = 0;
  if(qty != 0) qty = parseInt(qty) - 1;
  obj.val(qty);
  if(autoload) productNameAutoLoad(obj, true);
}
return false;
}

$(document).on('click', '.decrement_quantity_btn', function(){
  var id = $(this).data('id');
  var autoload = $(this).data('autoload');
  decrementQuantityById(id, autoload);
});

//　カート切り替え表示
$(document).on('click','#cart_open',function() {
  var status = "open"
  var post_cart_url = $('#post_cart_url').text();
  $.ajax({
    type: 'POST',
    url: '/' + post_cart_url + '/portal/remember_cart_toggle',
    data: { status: status },
    async: false,
    timeout: 10000,
    cache: false
  })
  .done(function(status) {
    $("#cart_archive_info").show();
    $("#cart_info").show();
    $("#cart_close").show();
    $("#cart_open").hide();
  });
});

$(document).on('click','#cart_close',function() {
  var status = "close"
  var post_cart_url = $('#post_cart_url').text();
  $.ajax({
    type: 'POST',
    url: '/' + post_cart_url + '/portal/remember_cart_toggle',
    data: { status: status },
    async: false,
    timeout: 10000,
    cache: false
  })
  .done(function(status) {
    $("#cart_archive_info").hide();
    $("#cart_info").hide();
    $("#cart_close").hide();
    $("#cart_open").show();
  });
});

// 時間指定画面遷移
// 引数
//   to_url:    リンク先URL
//   from_date: リンク開始日時
//   to_date:   リンク終了日時
window.toLinkOnTime = function(to_url, from_date, to_date) {
  var dtFrom = new Date(from_date);
  var dtTo = new Date(to_date);
  var dtNow = new Date();
  if (dtFrom <= dtNow && dtNow <= dtTo) {
    window.location.href = to_url;
  }
  return false;
}

// 未来企画 検索ボタン押下時
$(document).on('click', '#future_order_search_btn', function() {
  $('#future_products_search_form').submit();
});

/**
 * ふきだしの×ボタン押下で
 * 　sessionに現在時刻を格納
 * 　ふきだしを削除する
*/
window.closeDeliveryPush = async() => {
  const csrfToken = document.getElementsByName("csrf-token")[0].content;

  await fetch("/customer_information_group/set_session", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    }
  }).then(response => {
    document.getElementById("delivery_push").remove();
  });
};
