/*------------------------------------------------------------
 * レコメンドASPの sna.js の eフレンズ用追加関数
 * 画像非表示環境の場合、Timeoutを発生させ画面遷移する
 * 【注意事項】
 * レコメンドASPの sna.js が変更された場合、改修の必要あり
 *------------------------------------------------------------
 */
var __snahost = "re.veritrans.co.jp";

window.apiSetCtr2 = function(item_code,tmpl,link,k){
  apiSetCtr(item_code, tmpl, link, k);
  var id = setTimeout("location.href = '" + link.href + "';", 500);
  $(window).on("beforeunload", function() {
    clearTimeout(id);
  });
}
